const FixedFundraisingPools = [
    // 正式
    {
        poolIndex: 15,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: true,

        isLeft1: true,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: true,
        isSureClaim: false,
        isLink: true,
        igoType: 'many',
        poolName: 'Dx Spot#2',
        poolAddress: '0x22fe70362632ee427f7f386d1ab49b3b616bbdd2',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'DXS',
        earnTokenAddress: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        earnImg: require('@/assets/images/pool/DXSpot.png'),
        igoStarts: 'Feb 24th, 2PM UTC',
        igoEnds: 'Feb 24th, 5PM UTC',
        claimStarts: 'TBA',
        price: 0.0016,
        priceText: '1 DXS = 0.0016 BUSD',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'Whitelisted FCFS Pool',
        introduce: 'Dx Spot is an application based crypto trading bot platform that can place trades 24/7 on crypto exchanges. Dx Spot are simple and intuitive for crypto beginners or expert to setup and run.'
    },
    {
        poolIndex: 14,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: true,

        isLeft1: true,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: true,
        isSureClaim: false,
        isLink: true,
        igoType: 'many',
        poolName: 'Dx Spot',
        poolAddress: '0x318b34ee3fbbe5c3363cfcd8ef04b029c1dd060f',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'DXS',
        earnTokenAddress: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        earnImg: require('@/assets/images/pool/DXSpot.png'),
        igoStarts: 'Feb 24th, 10AM UTC',
        igoEnds: 'Feb 24th, 1PM UTC',
        claimStarts: 'TBA',
        price: 0.0016,
        priceText: '1 DXS = 0.0016 BUSD',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'Guaranteed Pool',
        introduce: 'Dx Spot is an application based crypto trading bot platform that can place trades 24/7 on crypto exchanges. Dx Spot are simple and intuitive for crypto beginners or expert to setup and run.'
    },
    {
        poolIndex: 13,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: true,

        isLeft1: true,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: true,
        isSureClaim: false,
        isLink: true,
        igoType: 'many',
        poolName: 'Duckie Land#2',
        poolAddress: '0x9286c6144ceff2d106e98df45e0cabf4e5384c14',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'MMETA',
        earnTokenAddress: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        earnImg: require('@/assets/images/pool/DuckieLand.jpeg'),
        igoStarts: 'February 18th,2PM UTC',
        igoEnds: 'February 18th,5PM UTC',
        claimStarts: 'February 22th,15.30PM UTC',
        price: 0.5,
        priceText: '1 MMETA = 0.5 BUSD',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'Whitelisted FCFS Pool',
        introduce: 'Duckie Land is a multiplatform multiplayer online game that runs on the blockchain, and each Duckie is a non-fungible token or NFT. Millions of people can participate in the NFT world and earn reward tokens through skillful gameplay and contribution to the ecosystem.'
    },
    {
        poolIndex: 12,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: true,

        isLeft1: true,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: true,
        isSureClaim: false,
        isLink: true,
        igoType: 'many',
        poolName: 'Duckie Land',
        poolAddress: '0xd6afb9afeeb10a5ac3b0015335e7d1f20c711979',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'MMETA',
        earnTokenAddress: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        earnImg: require('@/assets/images/pool/DuckieLand.jpeg'),
        igoStarts: 'February 18th,10AM UTC',
        igoEnds: 'February 18th,1PM UTC',
        claimStarts: 'February 22th,15.30PM UTC',
        price: 0.5,
        priceText: '1 MMETA = 0.5 BUSD',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'Guaranteed Pool',
        introduce: 'Duckie Land is a multiplatform multiplayer online game that runs on the blockchain, and each Duckie is a non-fungible token or NFT. Millions of people can participate in the NFT world and earn reward tokens through skillful gameplay and contribution to the ecosystem.'
    },
    {
        poolIndex: 0,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: true,
        isShow3: false,
        isShow4: false,
        isShow5: true,

        isLeft1: false,
        isLeft2: true,
        isLeft3: false,
        isLeft4: true,

        isPoolRefund: false,
        isSureClaim: true,
        isLink: true,
        igoType: 'one',
        poolName: 'TrustFi',
        poolAddress: '0xB23C1293e17b8E3ba6CD69fA1122f736487C40c5',
        stake: 'USDT',
        tokenAddress: '0x55d398326f99059ff775485246999027b3197955',
        earn: 'TFI',
        earnTokenAddress: '0x7565ab68D3F9DaDff127F864103C8c706Cf28235',
        earnImg: require('@/assets/images/pc/tfi.png'),
        igoStarts: '',
        igoEnds: '',
        claimStarts: '',
        price: 0,
        priceText: '',
        hardCap: 0,
        realization: 0,
        ratio: 0,
        poolRemainingTime: 0,
        remarks: '',
        introduce: 'TrustFi Network is a decentralized BaaS solution for DeFi market based on multichain environment, and focuses on early crypto assets issuance, liquidity management, community activities and DAO governance to unlock the potential of DeFi.'
    },
    {
        poolIndex: 1,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: false,
        isShow5: true,

        isLeft1: false,
        isLeft2: false,
        isLeft3: false,
        isLeft4: false,

        isPoolRefund: true,
        isSureClaim: true,
        isLink: true,
        igoType: 'many',
        poolName: 'CHEERS#1',
        poolAddress: '0x6bBf952E24808c81f78F30aB0f76dE9062985066',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'CHEERS',
        earnTokenAddress: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        earnImg: require('@/assets/images/pc/cheers_lp.png'),
        igoStarts: '10:00 AM 28th UTC',
        igoEnds: '',
        claimStarts: '3:30 PM 29th UTC',
        price: 8,
        priceText: '',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'TFI Stakers Pool',
        introduce: 'CheersLand is a scalable GameFi Metaverse Aggregator where anyone can monetize their gaming experiences and social networks. CheersLand runs the GameFi-as-a-Service model, dual-driven in Play to Earn and Build to Earn, with the product portfolio of Gamified Launchpad, Multi-game Universe, Multi-asset Staking Platform and NFT Market, and powered developing new users by the invitation mechanism, which builds a unique and diversified GameFi ecosystem.'
    },
    {
        poolIndex: 2,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: false,
        isShow5: true,

        isLeft1: false,
        isLeft2: false,
        isLeft3: false,
        isLeft4: false,

        isPoolRefund: true,
        isSureClaim: true,
        isLink: true,
        igoType: 'many',
        poolName: 'CHEERS#2',
        poolAddress: '0x1Ac5168ecaB0fA3889F41C9AA7Ca848Bb2e63c6c',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'CHEERS',
        earnTokenAddress: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        earnImg: require('@/assets/images/pc/cheers_lp.png'),
        igoStarts: '10:00 AM 28th UTC',
        igoEnds: '',
        claimStarts: '3:30 PM 29th UTC',
        price: 8,
        priceText: '',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'Gamers Pool',
        introduce: 'CheersLand is a scalable GameFi Metaverse Aggregator where anyone can monetize their gaming experiences and social networks. CheersLand runs the GameFi-as-a-Service model, dual-driven in Play to Earn and Build to Earn, with the product portfolio of Gamified Launchpad, Multi-game Universe, Multi-asset Staking Platform and NFT Market, and powered developing new users by the invitation mechanism, which builds a unique and diversified GameFi ecosystem.'
    },
    {
        poolIndex: 3,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: true,

        isLeft1: true,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: true,
        isSureClaim: true,
        isLink: true,
        igoType: 'many',
        poolName: 'CHEERS#3',
        poolAddress: '0x048fF6Bd822902621d198133624771E8BB366999',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'CHEERS',
        earnTokenAddress: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
        earnImg: require('@/assets/images/pc/cheers_lp.png'),
        igoStarts: '10:00 AM 29th UTC',
        igoEnds: '1:00 PM 29th UTC',
        claimStarts: '3:30 PM 29th UTC',
        price: 8,
        priceText: '',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'TFI Stakers Pool',
        introduce: 'CheersLand is a scalable GameFi Metaverse Aggregator where anyone can monetize their gaming experiences and social networks. CheersLand runs the GameFi-as-a-Service model, dual-driven in Play to Earn and Build to Earn, with the product portfolio of Gamified Launchpad, Multi-game Universe, Multi-asset Staking Platform and NFT Market, and powered developing new users by the invitation mechanism, which builds a unique and diversified GameFi ecosystem.'
    },
    {
        poolIndex: 4,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: false,

        isLeft1: false,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: false,
        isSureClaim: false,
        isLink: true,
        igoType: 'many',
        poolName: 'Meland.ai',
        poolAddress: '0xaf935ABbD99F85512Da34a89e09361171B910490',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'MELD',
        earnTokenAddress: '',
        earnImg: require('@/assets/images/pc/meland.png'),
        igoStarts: '7:00 AM 7th UTC',
        igoEnds: '10:00 AM 7th UTC',
        claimStarts: '',
        price: 181.81,
        priceText: '',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'TrustFi Flash IDO',
        introduce: 'MeLand.ai is the first Learn-to-Earn decentralized platform where players can challenge quizzes, create quizzes, build, own and monetize their gaming experience in the Polygon blockchain, token will be on BSC, called MELD.'
    },
    {
        poolIndex: 5,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: false,

        isLeft1: false,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: false,
        isSureClaim: false,
        isLink: true,
        igoType: 'many',
        poolName: 'Battle Saga',
        poolAddress: '0x8BffD44c088F93458C4b4A91E33165D6D1374B99',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'BTL',
        earnTokenAddress: '',
        earnImg: require('@/assets/images/pc/btl_logo.png'),
        igoStarts: '10:00 AM 21th UTC',
        igoEnds: '1:00 PM 21th UTC',
        claimStarts: '',
        price: 10,
        priceText: '',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'TrustFi Main IDO',
        introduce: 'Battle Saga is a Clash of Clan inspired decentralized Earn-Without-Playing(EWP) strategic metaverse game implementing innovative DAO system for the best user experience and adaptability.'
    },
    {
        poolIndex: 6,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: true,

        isLeft1: true,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: true,
        isSureClaim: true,
        isLink: true,
        igoType: 'many',
        poolName: 'Light Year',
        poolAddress: '0x393Da3Ff5B37d4D0fF90bccFF8Cf0fD3675664f7',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'LC',
        earnTokenAddress: '0x39BD64E5b9e025b0C8D91ee2CFec9125A388894B',
        earnImg: require('@/assets/images/pc/lc_logo.png'),
        igoStarts: '10:00 AM 29th UTC',
        igoEnds: '1:00 PM 29th UTC',
        claimStarts: '10:30 AM 30th UTC',
        price: 2.666,
        priceText: '1 LC = 0.15 BUSD',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'CheersLand IGO',
        introduce: 'Light Year is a blockchain based space strategy game. Mining natural resources, crafting space ships, battling and trading with other players, joining alliances or nations, the game brings players the wildest experience of space exploration. Moreover, under the hood, it’s a play-to-earn DeFi farming protocol that maximizes users’ return from staking their crypto assets.'
    },
    {
        poolIndex: 7,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: true,

        isLeft1: true,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: false,
        isSureClaim: false,
        isLink: true,
        igoType: 'many',
        poolName: 'Kingdom Karnage',
        poolAddress: '0x5C7c02d0a0f9469Ba671aE63C195206e58205b72',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'KKT',
        earnTokenAddress: '',
        earnImg: require('@/assets/images/pc/kkt_logo.png'),
        igoStarts: '9:00 AM 10th UTC',
        igoEnds: '12:00 AM 10th UTC',
        claimStarts: 'TBA',
        price: 83.333,
        priceText: '1 KKT = 0.012 BUSD',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'Private Sale',
        introduce: 'Kingdom Karnage is an Animated Combat NFT Trading Card Game currently playable cross platform.'
    },
    {
        poolIndex: 8,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: false,

        isLeft1: true,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: false,
        isSureClaim: false,
        isLink: true,
        igoType: 'many',
        poolName: 'MogWar',
        poolAddress: '0x5Ec7f168C8538ddFEE2F6ECfB1F950e3af956238',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'MONX',
        earnTokenAddress: '',
        earnImg: require('@/assets/images/pc/mogwar_logo.png'),
        igoStarts: '9:00 AM 14th UTC',
        igoEnds: '12:00 AM 14th UTC',
        claimStarts: 'TBA',
        price: 250,
        priceText: '1 MONX = 0.004 BUSD',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'TrustFi Flash IDO',
        introduce: 'Monster Of God is a strategy game combining Idle RPG specials and Autobattler. It is connected with blockchain technology, in which players can earn Monster Soul Potion (MSP) through PvP/PvE battles among Monsters and collect MONX coins through in-game tournament rewards.'
    },
    {
        poolIndex: 9,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: true,

        isLeft1: true,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: false,
        isSureClaim: false,
        isLink: true,
        igoType: 'many',
        poolName: 'Himo World',
        poolAddress: '0xcA5dc456B9027d01ef4fCcDC23aA76A7d66E5A03',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'HIMO',
        earnTokenAddress: '0x469aCf8e1F29C1B5Db99394582464Fad45A1fC6F',
        earnImg: require('@/assets/images/pc/himo_logo.png'),
        igoStarts: '12PM Jan. 23 UTC',
        igoEnds: '3PM Jan. 23 UTC',
        claimStarts: '12PM Jan. 24 UTC',
        price: 33.3333,
        priceText: '1 HIMO = 0.03 BUSD',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'CheersLand IGO',
        introduce: 'Himo World is an NFT tile-matching & deck building strategic puzzle game. It features Free-to-Play (F2P) and Play-to-Earn (P2E) mechanisms interwoven to Rogue-like PvE content and exciting PvP modes with multi-tier Tournaments.'
    },
    {
        poolIndex: 10,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: true,

        isLeft1: true,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: false,
        isSureClaim: false,
        isLink: true,
        igoType: 'many',
        poolName: 'Pandora',
        poolAddress: '0x87fFDF2c2582AB80F6033d23964E2e36099cDe96',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'DORA',
        earnTokenAddress: '',
        earnImg: require('@/assets/images/pc/dora_logo.png'),
        igoStarts: '12PM Jan. 25 UTC',
        igoEnds: '3PM Jan. 25 UTC',
        claimStarts: 'Jan. 27th TBA',
        price: 62.5,
        priceText: '1 DORA = 0.016 BUSD',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'CheersLand IGO',
        introduce: 'Pandora is a P2E open world exploration game where players can build their character and explore the world with friends; discovering new environments, species, equipment and much more. This is a blockchain-based Sandbox game that has many forms of PvE and PvP interaction.'
    },
    {
        poolIndex: 11,
        isStart: false,
        isEnd: false,

        isShow1: true,
        isShow2: false,
        isShow3: true,
        isShow4: true,
        isShow5: true,

        isLeft1: true,
        isLeft2: false,
        isLeft3: true,
        isLeft4: false,

        isPoolRefund: false,
        isSureClaim: false,
        isLink: true,
        igoType: 'many',
        poolName: 'Pandora#2',
        poolAddress: '0xDF5e63D7f4e1ABB225D6b7584dAE4254D0b0d237',
        stake: 'BUSD',
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        earn: 'DORA',
        earnTokenAddress: '',
        earnImg: require('@/assets/images/pc/dora_logo.png'),
        igoStarts: '12PM Jan. 26 UTC',
        igoEnds: '3PM Jan. 26 UTC',
        claimStarts: 'Jan. 27th TBA',
        price: 62.5,
        priceText: '1 DORA = 0.016 BUSD',
        hardCap: 'TBA',
        realization: 'TBA',
        ratio: 0,
        poolRemainingTime: 0,
        remarks: 'CheersLand IGO',
        introduce: 'Pandora is a P2E open world exploration game where players can build their character and explore the world with friends; discovering new environments, species, equipment and much more. This is a blockchain-based Sandbox game that has many forms of PvE and PvP interaction.'
    }
]

export default FixedFundraisingPools;
