<template>
  <layout v-if="!mobile">
    <div style="height: 159px"/>
    <div class="flex justify-center items-center rounded-3xl flex-col"
         style="border: solid 1px rgba(255, 255, 255, 0.1); height: 143px">
      <p class="text-white text-4xl font-bold">Welcome to our Gamified Launchpad in <span style="color: #05F1A9">CheersLand!</span>
      </p>
      <p class="text-white text-lg mt-1">Fasten your seatbelts and join the journey of Initial Gaming Offering (IGO) of
        startups to get new tokens!</p>
    </div>
    <div style="height: 22px"/>
    <div>
      <el-carousel trigger="click" height="calc(80vw * (427 / 1318))" :autoplay="true" indicator-position="none">
        <el-carousel-item>
          <div class="flex items-center justify-center">
            <img src="/images/igo/img_banner_01.png"/>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="flex items-center justify-center">
            <img src="/images/igo/img_banner_02.jpg"/>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="flex items-center justify-center">
            <img src="/images/igo/img_banner_03.jpg"/>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div style="height: 83px"/>
    <div class="flex text-white text-2xl text-center" style="line-height: 62px">
      <div class="w-1/2 unselect-panel" :class="{ 'select-panel': igoOption === 'left'}"
           @click="setIgoOption('left')">
        Recent IGO
      </div>
      <div class="w-1/2 unselect-panel" :class="{ 'select-panel': igoOption === 'right'}"
           @click="setIgoOption('right')">
        Past IGOs
      </div>
    </div>
    <div style="height: 80px"/>
    <div class="pool-list">
      <div
          class="card"
          v-for="(pool, index) in fundraisingPoolsForShow" :key="index"
      >
        <!--   card header   -->
        <div class="flex items-start" style="height: 232px">
          <div>
            <div class="pool-logo">
              <img :src="pool.earnImg"/>
            </div>
            <div style="height: 18px"/>
            <div class="pool-ddl">
              {{
                pool.poolAddress !== '0x000' ? (pool.isEnd ? 'CLOSED' : (pool.isStart ? 'OPEN' : 'IN ' +
                    pool.poolRemainingTime + ' DAYS')) : 'On the 28th'
              }}
            </div>
          </div>
          <div style="width: calc(100% - 96px)" class="flex justify-center flex-col items-center">
            <div class="pool-name">{{ pool.poolName }}</div>
            <div style="height: 19px"/>
            <div class="pool-token-name">{{ pool.earn }}/{{ pool.stake }}</div>
            <div style="height: 24px"/>
            <div class="pool-list-panel">
              <a :href="$store.state.aboutProject[pool.poolName].linka" target="_blank">
                <img src="/images/igo/icon_medium.png"/>
              </a>
              <a :href="$store.state.aboutProject[pool.poolName].linkb" target="_blank">
                <img src="/images/igo/icon_twitter.png"/>
              </a>
              <a :href="$store.state.aboutProject[pool.poolName].linkc" target="_blank">
                <img src="/images/igo/icon_telegram.png"/>
              </a>
              <a :href="$store.state.aboutProject[pool.poolName].linkd" target="_blank">
                <img src="/images/igo/icon_medium_m.png" style="margin-right: 0"/>
              </a>
            </div>
          </div>
        </div>
        <div class="horizontal-separator"/>
        <!--    card main part      -->
        <div style="height: 19px"/>
        <div class="pool-desc">{{ pool.remarks }}</div>
        <div style="height: 23px"/>
        <div class="pool-detail-row">
          <div style="width: 60%">
            <div v-if="pool.isShow1" class="detail-name">Sale Price</div>
            <div class="detail-value">
              <div v-if="pool.isShow1 && pool.priceText === ''">1 {{ pool.stake }} = {{ pool.price }}
                {{ pool.earn }}
              </div>
              <div v-if="pool.isShow1 && pool.priceText !== ''">{{ pool.priceText }}</div>
              <div v-if="pool.isShow2">{{ setDecimal(pool.realization, 6, false, false) }} {{ pool.stake }}
                ({{ setDecimal(pool.ratio, 3, false, false) }}%)
              </div>
            </div>
          </div>
          <div>
            <div class="detail-name">{{ pool.isLeft2 ? 'Total Committed' : 'IGO Starts' }}</div>
            <div class="detail-value">
              <div v-show="pool.isShow2">{{ pool.hardCap }} {{ pool.stake }}</div>
              <div v-show="pool.isShow3">{{ pool.igoStarts }}</div>
            </div>
          </div>
        </div>
        <div style="height: 21px"/>
        <div class="pool-detail-row">
          <div style="width: 60%">
            <div class="detail-name">{{ pool.isLeft3 ? 'IGO Ends:' : '' }}</div>
            <div class="detail-value">
              <div v-show="pool.isShow4">{{ pool.igoEnds }}</div>
            </div>
          </div>
          <div>
            <div class="detail-name" v-if="pool.isShow5">{{ pool.isLeft4 ? 'Hard Cap:' : 'Claim Starts:' }}</div>
            <div class="detail-value">
              <div v-show="pool.isShow5">{{ pool.claimStarts }}</div>
            </div>
          </div>
        </div>
        <div style="height: 28px"/>
        <div class="pool-detail-btn" @click="toPoolInfo(
                            pool.poolIndex,
                            pool.poolName,
                            pool.igoType,
                            pool.poolAddress,
                            pool.stake,
                            pool.tokenAddress,
                            pool.price,
                            pool.priceText,
                            pool.hardCap,
                            pool.earnTokenAddress,
                            pool.earn,
                            pool.earnImg,
                            pool.isPoolRefund,
                            pool.isLink,
                            pool.isSureClaim,
                            pool.introduce
                        )" v-if="pool.poolAddress !== '0x000'">
          {{ (pool.isStart && !pool.isEnd) ? 'Join' : 'Details' }}
        </div>
      </div>
    </div>
    <div style="height: 110px"/>
    <div class="flex justify-between items-center">
      <div style="border: 1px solid #FFFFFF; width: 287px; height: 0"/>
      <div class="take-part-title">How To Take Part</div>
      <div style="border: 1px solid #FFFFFF; width: 287px; height: 0"/>
    </div>
    <div style="height: 34px"/>
    <div class="take-part-item" v-for="(item, idx) in takePartItemList" :key="idx">
      <div class="header">0{{ idx + 1 }}</div>
      <div class="content">
        <div>
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
        <div class="act-btn" v-if="idx === 0" @click="toFarm()">Join the Farms</div>
      </div>
    </div>
    <div style="height: 45px"/>
    <div class="take-part-tips">
      <span style="color: #05F1A9">Tips:</span><br/>
      1. IGO usually lasts one hour and everyone can participate without any fee.<br/>
      2. Remember to add the Official Contract Address of IGO tokens into your wallet.
    </div>
    <div style="height: 88px"/>
  </layout>
  <mobile-layout v-else>
    <div class="mobile-wrapper">
      <div style="height: 1.42rem"/>
      <div class="m-top-banner">
        <div>
          Welcome to our Gamified Launchpad in <span style="color: #05F1A9">CheersLand!</span>
        </div>
        <p>
          Fasten your seatbelts and join the journey of Initial Gaming Offering (IGO) of startups to get new tokens!
        </p>
      </div>
      <div style="height: 0.32rem"/>
      <el-carousel :interval="5000" trigger="click" height="calc(3.02rem * (427 / 1318))" :autoplay="true"
                   indicator-position="none" style="width: 3.02rem; margin: 0 auto">
        <el-carousel-item>
          <div class="flex items-center justify-center">
            <img src="/images/igo/img_banner_01.png"/>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="flex items-center justify-center">
            <img src="/images/igo/img_banner_02.jpg"/>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="flex items-center justify-center">
            <img src="/images/igo/img_banner_03.jpg"/>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div style="height: 0.45rem"/>
      <div class="flex text-white text-center mx-auto" style="line-height: 0.39rem; width: 3.05rem">
        <div class="w-1/2 m-unselect-panel" :class="{ 'm-select-panel': igoOption === 'left'}"
             @click="setIgoOption('left')">
          Recent IGO
        </div>
        <div class="w-1/2 m-unselect-panel" :class="{ 'm-select-panel': igoOption === 'right'}"
             @click="setIgoOption('right')">
          Past IGOs
        </div>
      </div>
      <div style="height: 0.4rem"/>
      <div
          class="pool-list-window"
          :style="{ height: '3.5rem' }"
      >
        <div
            class="pool-list"
            :style="{ transform: `translateX(${poolListOffset}rem)` }"
        >
          <div
              class="m-card"
              v-for="(pool, index) in fundraisingPoolsForShow" :key="index"
          >
            <!--   card header   -->
            <div class="flex items-start" style="height: 1.5rem">
              <div>
                <div class="pool-logo">
                  <img :src="pool.earnImg"/>
                </div>
                <div style="height: 0.11rem"/>
                <div class="pool-ddl">
                  {{
                    pool.poolAddress !== '0x000' ? (pool.isEnd ? 'CLOSED' : (pool.isStart ? 'OPEN' : 'IN ' +
                        pool.poolRemainingTime + ' DAYS')) : 'On the 28th'
                  }}
                </div>
              </div>
              <div style="width: 1.89rem" class="flex justify-center flex-col items-center">
                <div class="pool-name">{{ pool.poolName }}</div>
                <div style="height: 0.12rem"/>
                <div class="pool-token-name">{{ pool.earn }}/{{ pool.stake }}</div>
                <div style="height: 0.16rem"/>
                <div class="pool-list-panel">
                  <a :href="$store.state.aboutProject[pool.poolName].linka" target="_blank">
                    <img src="/images/igo/icon_medium.png"/>
                  </a>
                  <a :href="$store.state.aboutProject[pool.poolName].linkb" target="_blank">
                    <img src="/images/igo/icon_twitter.png"/>
                  </a>
                  <a :href="$store.state.aboutProject[pool.poolName].linkc" target="_blank">
                    <img src="/images/igo/icon_telegram.png"/>
                  </a>
                  <a :href="$store.state.aboutProject[pool.poolName].linkd" target="_blank">
                    <img src="/images/igo/icon_medium_m.png" style="margin-right: 0"/>
                  </a>
                </div>
              </div>
            </div>
            <div class="horizontal-separator"/>
            <!--    card main part      -->
            <div style="height: 0.12rem"/>
            <div class="pool-desc">{{ pool.remarks }}</div>
            <div style="height: 0.14rem"/>
            <div class="pool-detail-row">
              <div style="width: 60%">
                <div v-if="pool.isShow1" class="detail-name">Sale Price</div>
                <div class="detail-value">
                  <div v-if="pool.isShow1 && pool.priceText === ''">1 {{ pool.stake }} = {{ pool.price }}
                    {{ pool.earn }}
                  </div>
                  <div v-if="pool.isShow1 && pool.priceText !== ''">{{ pool.priceText }}</div>
                  <div v-if="pool.isShow2">{{ setDecimal(pool.realization, 6, false, false) }} {{ pool.stake }}
                    ({{ setDecimal(pool.ratio, 3, false, false) }}%)
                  </div>
                </div>
              </div>
              <div>
                <div class="detail-name">{{ pool.isLeft2 ? 'Total Committed' : 'IGO Starts' }}</div>
                <div class="detail-value">
                  <div v-show="pool.isShow2">{{ pool.hardCap }} {{ pool.stake }}</div>
                  <div v-show="pool.isShow3">{{ pool.igoStarts }}</div>
                </div>
              </div>
            </div>
            <div style="height: 0.11rem"/>
            <div class="pool-detail-row">
              <div style="width: 60%">
                <div class="detail-name">{{ pool.isLeft3 ? 'IGO Ends:' : '' }}</div>
                <div class="detail-value">
                  <div v-show="pool.isShow4">{{ pool.igoEnds }}</div>
                </div>
              </div>
              <div>
                <div class="detail-name" v-if="pool.isShow5">{{ pool.isLeft4 ? 'Hard Cap:' : 'Claim Starts:' }}</div>
                <div class="detail-value">
                  <div v-show="pool.isShow5">{{ pool.claimStarts }}</div>
                </div>
              </div>
            </div>
            <div style="height: 0.16rem"/>
            <div class="pool-detail-btn" @click="toPoolInfo(
                            pool.poolIndex,
                            pool.poolName,
                            pool.igoType,
                            pool.poolAddress,
                            pool.stake,
                            pool.tokenAddress,
                            pool.price,
                            pool.priceText,
                            pool.hardCap,
                            pool.earnTokenAddress,
                            pool.earn,
                            pool.earnImg,
                            pool.isPoolRefund,
                            pool.isLink,
                            pool.isSureClaim,
                            pool.introduce
                        )" v-if="pool.poolAddress !== '0x000'">
              {{ (pool.isStart && !pool.isEnd) ? 'Join' : 'Details' }}
            </div>
          </div>
        </div>
      </div>
      <div style="height: 0.4rem"/>
      <div class="scroll-panel">
        <div class="prev-btn" @click="goPrevPool">
          <img src="/images/igo/mobile/icon_arrow_left.png"/>
        </div>
        <div class="next-btn" @click="goNextPool">
          <img src="/images/igo/mobile/icon_arrow_right.png"/>
        </div>
      </div>
      <div style="height: 0.43rem"/>
      <div class="m-take-part-title">How To Take Part</div>
      <div style="height: 0.42rem"/>
      <div>
        <div class="m-take-part-item" v-for="(item, idx) in takePartItemList" :key="idx">
          <div class="header">0{{ idx + 1 }}</div>
          <div style="height: 0.34rem"/>
          <div class="title">{{ item.title }}</div>
          <div style="height: 0.06rem"/>
          <div class="desc">{{ item.desc }}</div>
          <div class="act-btn" v-if="idx === 0" @click="toFarm()">Join the Farms</div>
          <div style="height: 0.31rem"/>
        </div>
      </div>
      <div style="height: 0.53rem"/>
      <div class="take-part-tips">
        <span>Tips:</span><br/>
        1. IGO usually lasts one hour and everyone can participate without any fee.<br/>
        2. Remember to add the Official Contract Address of IGO tokens into your wallet.
      </div>
      <div style="height: 0.34rem"/>
    </div>
  </mobile-layout>
</template>

<script>
import {mixin1} from "../../tools/mixins";
import * as metaMaskTools from "../../tools/chain";
import toolUtils from "../../tools/toolUtils";
import {BigNumberDiv} from "../../tools";
import FixedFundraisingPoolList from "@/consts/fixedFundraisingPoolList";
import Layout from "@/components/v2/Layout";
import isMobile from "@/consts/isMobile";
import MobileLayout from "@/components/v2/MobileLayout";

export default {
  name: "Index",
  mixins: [mixin1],
  components: {
    MobileLayout,
    Layout,
  },
  data() {
    return {
      mobile: isMobile(),
      igoOption: 'left',
      fundraisingPools: [],
      accounts: localStorage.getItem('accounts'),
      takePartItemList: [
        {
          title: 'Get Whitelisted',
          desc: 'Play the game to get yourself on Ranking List, or join the Farms and stake the required amount of tokens before the IGO.'
        },
        {
          title: 'Prepare Funds',
          desc: 'Before the IGO, you need to get your fund prepared in your wallet, such as USDT, BUSD, BNB, CHEERS, etc..'
        },
        {
          title: 'Commit Funds',
          desc: 'While the IGO sale is live, you can \'commit\' your funds to buy IGO tokens.'
        },
        {
          title: 'Claim your Tokens',
          desc: 'After the IGO, you can claim IGO tokens that you bought according to the proportion and the unspent funds.'
        }
      ],
      poolListOffset: 0
    };
  },
  watch: {
    async accounts() {
      await this.loadRemoteConfig()
      this.initFundraisingPools()
    },
    fundraisingPoolsForShow() {
      this.poolListOffset = 0;
    },
  },
  computed: {
    fundraisingPoolsForShow() {
      const poolsFilterByStatus = this.fundraisingPools
          .filter((pool) => {
            return (this.igoOption === 'left') ? !pool.isEnd : pool.isEnd;
          })
      if (this.igoOption === 'left') return poolsFilterByStatus;
      return poolsFilterByStatus.sort((a, b) => {
        return b.fundEndingTime - a.fundEndingTime
      })
    }
  },
  created() {
    if (this.mobile) {
      document.documentElement.style.fontSize =
          document.documentElement.clientWidth / 4.14 + "px";
    }
    setTimeout(() => {
      if (this.$store.state.accounts.length === 0) {
        this.$store.commit('setState', {
          connectWalletShow: true
        })
      }
      this.onAccountsChanged();
    }, 1000)
  },
  async mounted() {
    await this.loadRemoteConfig()
    this.initFundraisingPools()
  },
  beforeDestroy() {
  },
  methods: {
    goPrevPool() {
      const maxOffset = -1 * this.fundraisingPoolsForShow.length * 2.87 + 4.14;
      if (this.poolListOffset + 2.87 >= 0) {
        this.poolListOffset = 0;
      } else if (this.poolListOffset <= maxOffset) {
        this.poolListOffset = this.poolListOffset + 2.22;
      } else {
        this.poolListOffset = this.poolListOffset + 2.87;
      }
    },
    goNextPool() {
      const maxOffset = -1 * this.fundraisingPoolsForShow.length * 2.87 + 4.14;
      if (this.poolListOffset >= 0) {
        this.poolListOffset = -2.22;
      } else if (this.poolListOffset - 2.87 <= maxOffset) {
        this.poolListOffset = maxOffset;
      } else {
        this.poolListOffset = this.poolListOffset - 2.87;
      }
    },
    async loadRemoteConfig() {
      await this.$store.dispatch('getRemotePoolConfig', this.accounts);
      this.fundraisingPools = [...this.$store.state.remotePoolInfo, ...FixedFundraisingPoolList]
    },
    async onRefresh(accounts) {
      console.log('on refresh', accounts)
      this.$store.commit('setState', {
        accounts
      });
      localStorage.setItem('accounts', accounts);
      if (localStorage.getItem('accounts')) {
        this.accounts = localStorage.getItem('accounts')
      }
    },
    setIgoOption(text) {
      this.igoOption = text
    },
    setDecimal(numVal, numDecimalMax, roundUp, science) {
      return toolUtils.setDecimal(numVal, numDecimalMax, roundUp, science)
    },
    toPoolInfo(poolIndex, poolName, igoType, contract, lpType, lpToken, lpPrice, priceText, hardCap, earnToken, earn, earnImg, isPoolRefund, isLink, isSureClaim, introduce) {
      sessionStorage.setItem("poolIndex", poolIndex)
      sessionStorage.setItem("poolName", poolName)
      sessionStorage.setItem("igoType", igoType)
      sessionStorage.setItem("poolContract", contract)
      sessionStorage.setItem("lpType", lpType)
      sessionStorage.setItem("lpToken", lpToken)
      sessionStorage.setItem("lpPrice", lpPrice)
      sessionStorage.setItem("priceText", priceText)
      sessionStorage.setItem("hardCap", hardCap)
      sessionStorage.setItem("earnToken", earnToken)
      sessionStorage.setItem("earn", earn)
      sessionStorage.setItem("earnImg", earnImg)
      sessionStorage.setItem("isPoolRefund", isPoolRefund)
      sessionStorage.setItem("isLink", isLink)
      sessionStorage.setItem("isSureClaim", isSureClaim)
      sessionStorage.setItem("introduce", introduce)
      this.$router.push({
        path: "/pool",
        query: {
          igoPoolType: 0,
          poolIndexNum: poolIndex
        }
      });
    },
    toFarm() {
      this.$router.push("/mining");
    },
    async isStartFundRaising(contract, index) {
      try {
        await metaMaskTools.isStartFundRaising(contract).then(async (data) => {
          this.fundraisingPools[index].isStart = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async isEndFundRaising(contract, index) {
      try {
        const [isEnd, fundEndingTime] = await Promise.all([
          metaMaskTools.isEndFundRaising(contract),
          metaMaskTools.getFundEndingTime(contract),
        ])
        this.fundraisingPools[index].isEnd = isEnd
        this.fundraisingPools[index].fundEndingTime = Number(fundEndingTime)
      } catch (e) {
        console.log(e)
      }
    },
    async remainingTime(contract, index) {
      try {
        await metaMaskTools.remainingTime(contract, 0).then(async (data) => {
          this.fundraisingPools[index].poolRemainingTime = parseInt(BigNumberDiv(data, (60 * 60 * 24)))
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getExchangePoolDetails(contract, index) {
      try {
        await metaMaskTools.getExchangePoolDetails(contract).then(async (data) => {
          if (contract === '0xB23C1293e17b8E3ba6CD69fA1122f736487C40c5') {
            this.fundraisingPools[index].price = parseFloat(data[9])
          } else {
            this.fundraisingPools[index].price = BigNumberDiv(parseFloat(data[9]), 1e18)
          }
          this.fundraisingPools[index].hardCap = BigNumberDiv(parseFloat(data[7]), 1e18)
          this.fundraisingPools[index].realization = BigNumberDiv(parseFloat(data[10]), 1e18)

          this.fundraisingPools[index].ratio = BigNumberDiv(parseFloat(this.fundraisingPools[index].realization) * 100, parseFloat(this.fundraisingPools[index].hardCap))
        });
      } catch (e) {
        console.log(e)
      }
    },
    initFundraisingPools() {
      for (let i = 0; i < this.fundraisingPools.length; i++) {
        if (this.fundraisingPools[i].poolAddress !== '0x000') {
          this.getExchangePoolDetails(this.fundraisingPools[i].poolAddress, i)
          this.remainingTime(this.fundraisingPools[i].poolAddress, i)
          this.isStartFundRaising(this.fundraisingPools[i].poolAddress, i)
          this.isEndFundRaising(this.fundraisingPools[i].poolAddress, i)
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.select-panel {
  border-bottom: solid 2px #00DFFF !important;
}

.unselect-panel {
  cursor: pointer;
  border-bottom: solid 1px rgba(#00DFFF, 0.3);
}

.pool-list {
  position: relative;
  display: flex;
  align-items: flex-start;
  transition: height 0.3s ease-out;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;

  .card:nth-child(3n) {
    margin-right: 0;
  }

  .card {
    position: relative;
    width: 30%;
    height: auto;
    min-height: 531px;
    background-size: 100% 100%;
    background-image: url("/images/igo/bg_pool_card.png");
    text-align: center;
    padding: 0 3.75% 60px;
    margin-right: 5%;

    .pool-logo {
      margin-top: 65px;
      width: 96px;
      height: 96px;
      background-size: cover;
      background-image: url("/images/igo/bg_pool_logo.png");
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 90%;
        border-radius: 50%;
      }
    }

    .pool-ddl {
      width: 88px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #00DFFF;
      border-radius: 5px;
      font-size: 14px;
      line-height: 110%;
      color: #0C0530;
    }

    .pool-name {
      padding-top: 78px;
      font-weight: 700;
      font-size: 22px;
      line-height: 104.5%;
      text-transform: uppercase;
      color: #FFFFFF;
    }

    .pool-token-name {
      width: 146px;
      height: 42px;
      background: #FB8F10;
      border-radius: 30px;
      font-size: 16px;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .pool-list-panel {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 12px;
        margin-right: 10px;
      }
    }

    .horizontal-separator {
      opacity: 0.3;
      border: 0.5px solid #FFFFFF;
    }

    .pool-desc {
      font-weight: 700;
      font-size: 18px;
      line-height: 104.5%;
      letter-spacing: 0.105em;
      text-transform: uppercase;
      color: #FFFFFF;
    }

    .pool-detail-row {
      display: flex;
      text-align: left;

      .detail-name {
        font-weight: 700;
        font-size: 14px;
        line-height: 141.25%;
        color: #1AFFFF;
        white-space: nowrap;
      }

      .detail-value {
        font-size: 14px;
        line-height: 141.25%;
        color: #FFFFFF;
      }
    }

    .pool-detail-btn {
      cursor: pointer;
      width: 175px;
      height: 46px;
      background-size: 175px 46px;
      background-image: url("/images/igo/bg_detail_btn.png");
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 17.4143px;
      line-height: 110%;
      color: #0C0530;
      margin: 0 auto;
    }
  }
}

.take-part-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 57px;
  color: #FFFFFF;
}

.take-part-item {
  position: relative;
  height: 148px;
  margin-bottom: 34px;

  .header {
    position: absolute;
    left: 0;
    top: 0;
    width: 236px;
    height: 148px;
    background: #FB8F10;
    border-radius: 19.1043px;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    padding-left: 61px;
    display: flex;
    align-items: center;
  }

  .content {
    position: absolute;
    top: 0;
    left: 142px;
    width: calc(100% - 142px);
    height: 148px;
    background: #0C0530;
    border-radius: 25px;
    border: solid 0.5px #05F1A9;
    padding-left: 44px;
    padding-right: 50px;
    display: flex;
    align-items: center;


    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: #FFFFFF;
    }

    .desc {
      margin-top: 8px;
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
    }

    .act-btn {
      cursor: pointer;
      height: 44px;
      border: 1px solid #FB8F10;
      border-radius: 30px;
      font-size: 16px;
      color: #FB8F10;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 25px;
      margin-left: 73px;
      flex-shrink: 0;
    }
  }
}

.take-part-tips {
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: white;
  text-align: center;
}

@media screen and (max-width: 1600px) {
  .detail-value {
    font-size: 12px !important;
  }

  .pool-name {
    font-size: 18px !important;
  }
}
</style>
<style lang="less" scoped>
.mobile-wrapper {
  .m-top-banner {
    width: 3.02rem;
    margin: 0 auto;
    border: 1px solid rgba(#FFFFFF, 0.1);
    border-radius: 20px;
    padding: 0.25rem 0;

    div {
      font-weight: 700;
      font-size: 0.3rem;
      line-height: 0.38rem;
      text-align: center;
      color: #FFFFFF;
      padding: 0 0.32rem;
      margin-bottom: 0.17rem;
    }

    p {
      font-size: 0.14rem;
      line-height: 147.5%;
      text-align: center;
      color: #FFFFFF;
      padding: 0 0.22rem;
    }
  }

  .m-unselect-panel {
    font-weight: 500;
    font-size: 0.16rem;
    color: #FFFFFF;
    border-bottom: 0.375623px solid rgba(#00DFFF, 0.3);
  }

  .m-select-panel {
    border-bottom: 1px solid #00DFFF;
  }

  .pool-list-window {
    width: 4.14rem;
    overflow: hidden;
  }

  .pool-list {
    display: block;
    white-space: nowrap;
    position: relative;
    transition: transform 0.2s ease-out;
    transform: translateX(0);

    .m-card {
      display: inline-block;
      width: 2.87rem;
      height: auto;
      background-size: 2.87rem 100%;
      background-image: url("/images/igo/bg_pool_card.png");
      text-align: center;
      padding: 0 0.35rem 0.35rem;

      .pool-logo {
        margin-top: 0.42rem;
        width: 0.62rem;
        height: 0.62rem;
        background-size: cover;
        background-image: url("/images/igo/bg_pool_logo.png");
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 90%;
          border-radius: 50%;
        }
      }

      .pool-ddl {
        width: 0.58rem;
        height: 0.16rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00DFFF;
        border-radius: 5px;
        font-size: 0.1rem;
        color: #0C0530;
      }

      .pool-name {
        padding-top: 0.48rem;
        font-weight: 700;
        font-size: 0.16rem;
        line-height: 104.5%;
        text-transform: uppercase;
        color: #FFFFFF;
      }

      .pool-token-name {
        width: 0.95rem;
        height: 0.27rem;
        background: #FB8F10;
        border-radius: 18px;
        font-size: 0.12rem;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .pool-list-panel {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 0.12rem;
          margin-right: 0.1rem;
        }
      }

      .horizontal-separator {
        opacity: 0.3;
        border: 0.5px solid #FFFFFF;
      }

      .pool-desc {
        font-weight: 700;
        font-size: 0.12rem;
        line-height: 104.5%;
        letter-spacing: 0.105em;
        text-transform: uppercase;
        color: #FFFFFF;
      }

      .pool-detail-row {
        display: flex;
        text-align: left;

        .detail-name {
          font-weight: 700;
          font-size: 0.1rem;
          line-height: 141.25%;
          color: #1AFFFF;
          white-space: nowrap;
        }

        .detail-value {
          font-size: 0.1rem;
          line-height: 141.25%;
          color: #FFFFFF;
        }
      }

      .pool-detail-btn {
        cursor: pointer;
        width: 1.13rem;
        height: 0.3rem;
        background-size: 1.13rem 0.3rem;
        background-image: url("/images/igo/bg_detail_btn.png");
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 0.14rem;
        line-height: 110%;
        color: #0C0530;
        margin: 0 auto;
      }
    }
  }

  .scroll-panel {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 0.12rem;
    }

    .prev-btn {
      width: 0.39rem;
      height: 0.39rem;
      border: 0.68421px solid #9b9b9b;
      margin-right: 0.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .next-btn {
      width: 0.39rem;
      height: 0.39rem;
      background: #fb8f10;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }

  .m-take-part-title {
    font-weight: 700;
    font-size: 0.28rem;
    line-height: 0.57rem;
    color: #FFFFFF;
    text-align: center;
  }

  .m-take-part-item:last-child {
    margin-bottom: 0;
  }

  .m-take-part-item {
    position: relative;
    width: 3.02rem;
    border-radius: 6.92568px;
    margin: 0 auto 0.88rem;
    border: 0.5px solid #05F1A9;
    padding: 0 0.31rem;
    text-align: center;

    .header {
      position: absolute;
      left: 50%;
      top: -0.3rem;
      width: 236px;
      height: 0.3rem;
      transform: translateX(-50%);
      background: #FB8F10;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      font-weight: 600;
      font-size: 0.2rem;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      font-weight: 600;
      font-size: 0.18rem;
      line-height: 0.24rem;
      color: #FFFFFF;
    }

    .desc {
      font-size: 0.14rem;
      line-height: 0.21rem;
      color: #FFFFFF;
    }

    .act-btn {
      cursor: pointer;
      height: 0.44rem;
      border: 1px solid #FB8F10;
      border-radius: 30px;
      font-size: 0.16rem;
      color: #FB8F10;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.25rem;
      margin-top: 0.11rem;
    }
  }

  .take-part-tips {
    margin: 0 auto;
    width: 2.4rem;
    font-size: 0.16rem;
    line-height: 0.31rem;
    color: white;
    text-align: center;

    span {
      color: #05F1A9;
      font-size: 0.2rem;
      font-weight: 700;
    }
  }
}
</style>
